import './HomePage.css'
import Toolbar from '../toolbar/Toolbar'
import pp from './../images/pp.png'
import Typewriter from 'typewriter-effect'
import Footer from '../footer/Footer'

export default function Home() {

    return  <>
                <title> Home </title>
                <div className='Home-Background'>
                    <Toolbar/>
                    <HomeDescription/>
                    <Footer/>
                </div>
    </>
}

function HomeDescription() {

    return  <div className='Home'>
                <img src={pp} alt='' className='Home-Logo'></img>
                <div className='Home-Description'>
                    <p>
                        Hello !<br/><br/>

                        <Typewriter options={{autoStart: true,loop: true,delay: 50,strings: ['My name is Rabbit89P23.']}}></Typewriter><br/>
                        
                        I am a French computer science student, specializing in networks, cloud and cybersecurity.<br/><br/>

                        I like to do SysAdmin and DevOps when I have a bit of time, even when I am not studying.<br/><br/>

                        I also enjoy taking part in CTFs, both solo and with a team. You can see my results <a href='/ctf'> here</a>. One of my favorite categories is cryptography, which allows me to use mathematics, sometimes in a very advanced way, to solve problems. I also post some Write-Ups on Github, so feel free to use them, and don't hesitate to open issues on Github if you have any questions or comments. 
                    </p>
                </div>
            </div>
}