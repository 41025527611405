// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    position: inherit;
    bottom: 100px;
    text-align: center;
    width: 100%;
    padding-bottom: 0.5vh;
  }

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
  
.App-logo {
    height: 3.8vh;
    pointer-events: none;
    margin-bottom: -9px;
}`, "",{"version":3,"sources":["webpack://./src/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,qBAAqB;EACvB;;AAEF;IACI;QACI,4CAA4C;IAChD;AACJ;;;AAGA;IACI;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;AACJ;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".footer {\n    position: inherit;\n    bottom: 100px;\n    text-align: center;\n    width: 100%;\n    padding-bottom: 0.5vh;\n  }\n\n@media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n        animation: App-logo-spin infinite 20s linear;\n    }\n}\n\n\n@keyframes App-logo-spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n}\n  \n.App-logo {\n    height: 3.8vh;\n    pointer-events: none;\n    margin-bottom: -9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
