// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CTF-Background {
    background-color: #282c34;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: fantasy;
    margin-bottom: -140px;
    height: auto;
  }
  

.CTF {
    min-height: 86vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
  }

.CTF-Item {
    font-size: calc(12px + 2vmin);
    text-align: center;
}

.CTF-Item-Name {
    font-size: calc(16px + 2vmin);
    font-weight: bold;
    margin-top: 60px;
}

.CTF-Item-Default {
    font-size: calc(12px + 2vmin);
    text-align: left;
    margin: 0;
}

.bullet-list {
    list-style-type: disc;
    text-align: left;
    padding-left: 20px; 
    margin: 10px 0 0 0;
}

.bullet-list li {
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/CTFPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,6BAA6B;IAC7B,YAAY;IACZ,oBAAoB;IACpB,qBAAqB;IACrB,YAAY;EACd;;;AAGF;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;EACtB;;AAEF;IACI,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".CTF-Background {\n    background-color: #282c34;\n    min-height: 100vh;\n    font-size: calc(10px + 2vmin);\n    color: white;\n    font-family: fantasy;\n    margin-bottom: -140px;\n    height: auto;\n  }\n  \n\n.CTF {\n    min-height: 86vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding-bottom: 40px;\n  }\n\n.CTF-Item {\n    font-size: calc(12px + 2vmin);\n    text-align: center;\n}\n\n.CTF-Item-Name {\n    font-size: calc(16px + 2vmin);\n    font-weight: bold;\n    margin-top: 60px;\n}\n\n.CTF-Item-Default {\n    font-size: calc(12px + 2vmin);\n    text-align: left;\n    margin: 0;\n}\n\n.bullet-list {\n    list-style-type: disc;\n    text-align: left;\n    padding-left: 20px; \n    margin: 10px 0 0 0;\n}\n\n.bullet-list li {\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
