import './LinksPage.css'
import Toolbar from '../toolbar/Toolbar'
import github_logo from './../images/github.png'
import cryptohack_logo from './../images/cryptohack.png'
import rootme_logo from './../images/rootme.png'
import Footer from '../footer/Footer'

export default function Links() {

    return  <>
                <title> Links </title>
                <div className='LinksPage-Background'>
                    <Toolbar/>
                    <div className='LinksPage'>
                        <LinksList/>
                    </div>
                    <Footer/>
                </div>
    </>
}

function LinksList() {

    return  <>
                <LinksItem logo={github_logo} pseudo='Rabbit89P23' name='Github' location='https://github.com/Rabbit89P23'></LinksItem>
                <LinksItem logo={rootme_logo} style={{filter: "invert(1)"}} pseudo='Rabbit89P23' name='Root Me' location='https://www.root-me.org/89P23'></LinksItem>
                <LinksItem logo={cryptohack_logo} pseudo='Rabbit89P23' name='Cryptohack' location='https://cryptohack.org/user/Rabbit89P23/'></LinksItem>
            </>
}

function LinksItem({name, pseudo, logo, location, ...props}) {

    return  <div className='LinksPage-Item'>
                <a href={location} target="_blank" rel="noopener noreferrer">
                    <img src={logo} className='LinksPage-Logo' alt='test' {...props} onClick={() => window.open(location)}></img>
                </a>
                    { name } : &nbsp;<a href={location} target="_blank" rel="noopener noreferrer" style={{ color: 'white', cursor: 'pointer' }}>
                    { pseudo }
                </a>
            </div>
}