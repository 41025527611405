import './CTFPage.css'
import Toolbar from '../toolbar/Toolbar'
import Footer from '../footer/Footer'

export default function CTF() {

    const shutlock_2024 = {name : 'Shutlock-2024', mode : 'Solo', rank : '73/621', best_rank : null, points : '1689', pseudo : 'Rabbit89P23', teamname : null, write_up : null};
    const ctf404_2024 = {name : '404CTF-2024', mode : 'Solo', rank : '131', best_rank : '91', points : '6353', pseudo : 'Rabbit89P23', teamname : null, write_up : 'https://github.com/Rabbit89P23/404ctf-2024'};
    const midnight_flag_ctf_final_2024 = {name : 'Midnight Flag (Final) - Operation BACKSLASH (2024)', mode : 'Team', rank : '14/23', best_rank : null, points : '5705', pseudo : 'Rabbit89P23', teamname : "Télécom Imp'Hackt", write_up : null};
    const midnight_flag_ctf_qualification_2024 = {name : 'Midnight Flag (Qualification) - Operation BACKSLASH (2024)', mode : 'Team', rank : '24/158', best_rank : null, points : '6017', pseudo : 'Rabbit89P23', teamname : "Télécom Imp'Hackt", write_up : null};
    const tracs_2023 = {name : 'TRACS 2023', mode : 'Team', rank : '14/82', best_rank : "1/82", points : '568', pseudo : null, teamname : "Télécom Imp'Hackt", write_up : null};
    const oteria_cyber_cup_2023 = {name : 'Oteria Cyber Cup 2023', mode : 'Team', rank : '4', best_rank : null, points : null, pseudo : null, teamname : "Télécom Imp'Hackt", write_up : null};

    return  <>
                <title> CTF </title>
                <div className='CTF-Background'>
                    <Toolbar/>
                    <div className='CTF'>
                        <div class='CTF-Item'>
                            <CTFItem data={shutlock_2024}/>
                            <CTFItem data={ctf404_2024}/>
                            <CTFItem data={midnight_flag_ctf_final_2024}/>
                            <CTFItem data={midnight_flag_ctf_qualification_2024}/>
                            <CTFItem data={tracs_2023}/>
                            <CTFItem data={oteria_cyber_cup_2023}/>
                        </div>
                    </div>
                    <Footer/>
                </div>
    </>
}

function CTFItem({ data }) {

    return  <>
                <h1 class='CTF-Item-Name'>{ data.name }</h1>
                <ul class='bullet-list'>
                    {data.pseudo && <li><p class='CTF-Item-Default'> <strong>Pseudo : </strong>{ data.pseudo }</p></li>}
                    <li><p class='CTF-Item-Default'> <strong>Mode : </strong>{ data.mode }</p></li>
                    {data.teamname && <li><p class='CTF-Item-Default'> <strong>TeamName : </strong>{ data.teamname }</p></li>}
                    <li><p class='CTF-Item-Default'> <strong>Rank : </strong>{ data.rank }</p></li>
                    {data.best_rank && <li><p class='CTF-Item-Default'> <strong>Best Rank : </strong>{ data.best_rank }</p></li>}
                    {data.points && <li><p class='CTF-Item-Default'> <strong>Points : </strong>{ data.points }</p></li>}
                    {data.write_up && <li><p class='CTF-Item-Default'> <strong>Write-Up : </strong><a href={ data.write_up }>{ data.write_up }</a></p></li>}
                </ul>
            </>
}