import './Toolbar.css'
import logo from './../images/logo.png'

export default function Toolbar(){

    return  <div className='Toolbar'>
                <ToolbarImage name='Home' location='/home'/>
                <ToolbarButton name='Home' location='/home'/>
                <ToolbarButton name='CTF' location='/ctf'/>
                <ToolbarButton name='Links' location='/links'/>

            </div>
}

function ToolbarButton({name, location}) {

    return <div className='Toolbar-Item'><button className='Toolbar-Button' onClick={() => window.location.href=location}> { name } </button></div>
}

function ToolbarImage({location}) {
    
    return <div className='Toolbar-Item'><img src={logo} className='Toolbar-Image' onClick={() => window.location.href=location} alt=''></img></div>

}