// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home-Background {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: fantasy;
}

.Home {
    min-height: 83vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

.Home-Logo {
    display: flex;
    height: 40vh;
  }

.Home-Description {
    display: flex;
    max-width: 40%;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: justify;
    word-wrap: break-word;
  }`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;EAC7B,YAAY;EACZ,oBAAoB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;EACpB;;AAEF;IACI,aAAa;IACb,YAAY;EACd;;AAEF;IACI,aAAa;IACb,cAAc;IACd,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,mBAAmB;IACnB,qBAAqB;EACvB","sourcesContent":[".Home-Background {\n  background-color: #282c34;\n  min-height: 100vh;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  font-family: fantasy;\n}\n\n.Home {\n    min-height: 83vh;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    height: fit-content;\n    width: fit-content;\n  }\n\n.Home-Logo {\n    display: flex;\n    height: 40vh;\n  }\n\n.Home-Description {\n    display: flex;\n    max-width: 40%;\n    width: fit-content;\n    height: fit-content;\n    text-align: justify;\n    word-wrap: break-word;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
