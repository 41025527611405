// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toolbar {
    background-color: #15171b;
    min-height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Toolbar-Item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Toolbar-Button {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-size: calc(8px + 2vmin);
    color: white;
    border-radius: 8px;
    border: none;
    padding: 8px 16px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: fantasy;
    font-weight: bold;
    cursor: pointer;
}

.Toolbar-Image {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: left;
    cursor: pointer;
}


.Toolbar-Button:hover {
    background-color: #454b58;
}`, "",{"version":3,"sources":["webpack://./src/toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,4BAA4B;IAC5B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".Toolbar {\n    background-color: #15171b;\n    min-height: 6vh;\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n    font-size: calc(10px + 2vmin);\n    color: white;\n}\n\n.Toolbar-Item {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.Toolbar-Button {\n    background-color: transparent;\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n    font-size: calc(8px + 2vmin);\n    color: white;\n    border-radius: 8px;\n    border: none;\n    padding: 8px 16px;\n    width: fit-content;\n    height: fit-content;\n    font-family: fantasy;\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.Toolbar-Image {\n    background-color: transparent;\n    display: flex;\n    flex-direction: row;\n    height: 50px;\n    justify-content: left;\n    cursor: pointer;\n}\n\n\n.Toolbar-Button:hover {\n    background-color: #454b58;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
