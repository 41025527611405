// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinksPage-Background {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: fantasy;
}


.LinksPage {
    display: flex;
    min-height: 83vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.LinksPage-Item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: calc(12px + 2vmin);
    margin: 15px;
  }

.LinksPage-Logo {
    width: 65px;
    height: 65px;
    object-fit: cover;
    margin-right: 8px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/LinksPage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;EAC7B,YAAY;EACZ,oBAAoB;AACtB;;;AAGA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY;EACd;;AAEF;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".LinksPage-Background {\n  background-color: #282c34;\n  min-height: 100vh;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  font-family: fantasy;\n}\n\n\n.LinksPage {\n    display: flex;\n    min-height: 83vh;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n\n.LinksPage-Item {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    height: fit-content;\n    width: fit-content;\n    font-size: calc(12px + 2vmin);\n    margin: 15px;\n  }\n\n.LinksPage-Logo {\n    width: 65px;\n    height: 65px;\n    object-fit: cover;\n    margin-right: 8px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
